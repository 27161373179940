<template>
  <div>

    <b-overlay
      id="overlay-background"
      :show="overlay"
      variant="light"
      opacity="0.50"
      blur="2px"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="4"
        >
          <b-card
            class="vh-90 overflow-auto"
          >
            <div class="d-flex justify-content-between">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning mb-2 mr-2"
                @click="openModal(null, false)"
              >
                <feather-icon icon="FolderIcon" />
                {{ $t('courses.create_folder') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary mb-2"
                @click="openFileModal(null, true)"
              >
                <feather-icon icon="FileIcon" />
                {{ $t('courses.add_file') }}
              </b-button>
            </div>
            <tree-collapse
              class="min-w"
              :all-options="true"
              :folder="GET_FOLDER_LIST"
              @click="openFileOrDirectory"
              @folder-add-btn="openModal"
              @file-add-btn="openFileModal"
              @edit-btn="openEditModal"
              @delete-folder="deleteFolders"
              @save-draged-file="saveDragedFile"
            />
          </b-card>
        </b-col>

        <b-col
          cols="8"
        >
          <b-overlay
            id="overlay-background"
            :show="overlay1"
            variant="light"
            opacity="0.50"
            blur="2px"
            rounded="sm"
          >
            <b-card
              class="vh-90 overflow-auto"
            >
              <b-row>

                <b-col
                  cols="12"
                  class="mb-1 d-flex justify-content-between"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="toBackBtn"
                    @click="toBack(parentId)"
                  >
                    <feather-icon
                      icon="ArrowLeftCircleIcon"
                      size="20"
                    />
                  </b-button>
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning mr-2"
                      @click="openModal(GET_ONE_FOLDER.id, false)"
                    >
                      <feather-icon icon="FolderIcon" />
                      {{ $t('courses.create_folder') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="openFileModal(GET_ONE_FOLDER.id, true)"
                    >
                      <feather-icon icon="FileIcon" />
                      {{ $t('courses.add_file') }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <all-doc-viewer
                v-if="onFile"
                class="vh-75"
                :src="src"
              />
              <b-row
                v-else
              >
                <template v-if="GET_MAIN_WINDOW.children.length">
                  <b-col
                    v-for="item in GET_MAIN_WINDOW.children"
                    :key="`folders${item.id}`"
                    md="2"
                    class="h-100 mb-2 position-relative"
                    @click="openFileOrDirectory(item)"
                  >
                    <div
                      class="d-flex flex-column justify-content-beetwen cursor-pointer hov"
                      style="height:140px"
                      :title="item.is_folder ? '' : item.file ? '' : item.url"
                    >
                      <feather-icon
                        class="h-auto w-auto p-2"
                        :class="item.is_folder ? 'text-warning' : item.file ? '' : 'text-primary'"
                        :icon="item.is_folder ? 'FolderIcon' : item.file ? 'FileIcon' : 'LinkIcon'"
                      />
                      <p
                        class="file-text text-center px-1"
                      >
                        {{ item.is_folder ? item.title : item.file ? showFile(item.file) : 'url' }}
                      </p>
                    </div>

                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="flat-warning"
                      class="dropdown-icon-wrapper position-absolute bl1"
                      toggle-class="rounded-circle p-25"
                      toggle-tag="span"
                      @click.stop=""
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle"
                        />
                      </template>
                      <b-dropdown-item
                        @click.stop="deleteFolders(item.id)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </template>
                <template v-else>
                  <b-col
                    cols="12"
                    class="text-center"
                  >
                    <h4>{{ $t('courses.this_folder_empty') }}</h4>
                  </b-col>
                </template>
              </b-row>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-overlay>
    <AddFileModal
      :is-file-add="isFileAdd"
      :edit-data="editData"
      :is-edit="isEdit"
      :parent="parent"
    />
    <b-modal
      id="saveDragedFile"
      hide-footer
      no-close-on-backdrop
      hide-header-close
    >
      <b-progress
        :value="progress"
        max="100"
        striped
        show-value
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BButton,
  BProgress,
  BDropdownItem,
  BDropdown,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AddFileModal from './components/AddFileModal.vue'
import areYouSure from '@/mixins/areYouSure'
import toast from '@/mixins/toast'
import AllDocViewer from '@/components/AllDocViewer.vue'
import TreeCollapse from '../../../components/TreeCollapse.vue'
import http from '@/libs/axios'
import formmatter from '@/mixins/formmatter'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BButton,
    AddFileModal,
    AllDocViewer,
    TreeCollapse,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BProgress,
  },
  directives: {
    Ripple,
  },
  mixins: [areYouSure, toast, formmatter],
  data() {
    return {
      isFileAdd: false,
      dataTree: [],
      tree: true,
      src: '',
      onFile: false,
      editData: {},
      isEdit: false,
      parentId: null,
      parent: null,
      childFolders: [],
      fileContent: [],
      toBackBtn: true,
      progress: 0,
      overlay: true,
      overlay1: false,
    }
  },
  computed: {
    ...mapGetters('content', ['GET_FOLDER_LIST', 'GET_ONE_FOLDER', 'GET_MAIN_WINDOW']),
  },
  async mounted() {
    this.tree = false
    await this.FETCH_FOLDERS_LIST().then(res => {
      this.childFolders = res.results
    })
    this.$nextTick(() => {
      this.tree = true
      this.overlay = false
    })
  },
  methods: {
    ...mapMutations('content', ['SET_MAIN_WINDOW']),
    ...mapActions('content', [
      'FETCH_FOLDERS_LIST',
      'FETCH_ONE_FOLDER',
      'DELETE_FOLDERS',
      'CREATE_FOLDERS',
    ]),
    openModal(id, modalType) {
      this.isEdit = false
      this.parent = id
      this.isFileAdd = modalType
      this.$nextTick(() => {
        this.$bvModal.show('AddFileModal')
      })
    },
    openFileModal(id, modalType) {
      this.parent = id
      this.isEdit = false
      this.isFileAdd = modalType
      this.$nextTick(() => {
        this.$bvModal.show('AddFileModal')
      })
    },
    openEditModal(data) {
      this.isEdit = true
      this.isFileAdd = false
      this.editData = data
      this.$nextTick(() => {
        this.$bvModal.show('AddFileModal')
      })
    },
    deleteFolders(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_FOLDERS(id)
            .then(() => {
              this.$_successToast('Успешно удалено')
              this.FETCH_FOLDERS_LIST()
            })
            .catch(() => {
              this.$_errorToast('Ошибка')
            })
        }
      })
    },
    async saveDragedFile(file, id) {
      this.progress = 0
      const savedData = {
        parent: id,
        file,
        is_folder: false,
      }
      const formData = this.$_objectToFormData(savedData)
      this.$bvModal.show('saveDragedFile')
      await http.post('/file_management/content-file-folder/', formData, {
        onUploadProgress: progressEvent => {
          this.progress = (progressEvent.loaded * 100) / progressEvent.total
        },
        timeout: 0,
      })
        .then(() => {
          this.$_successToast('Успешно Добавлено')
          this.$bvModal.hide('saveDragedFile')
          this.FETCH_FOLDERS_LIST().then(() => {
            if (id) this.FETCH_ONE_FOLDER(id)
          })
        })
        .catch(() => {
          this.$_errorToast('Ошибка')
          this.$bvModal.hide('saveDragedFile')
        })
    },
    openFileOrDirectory(node) {
      this.src = null
      if (!node.is_folder) {
        this.onFile = true
        this.parentId = node.parent
        this.toBackBtn = false
        if (node.file) {
          this.$nextTick(() => {
            this.src = node.file
          })
        } else {
          this.$nextTick(() => {
            this.src = node.url
          })
        }
      } else {
        this.SET_MAIN_WINDOW(node)
        this.onFile = false
        this.childFolders = node.children
        this.parentId = node.parent
        this.toBackBtn = false
      }
    },
    async toBack(data) {
      this.src = null
      this.overlay1 = true
      if (data) {
        await this.FETCH_ONE_FOLDER(data).then(res => {
          this.onFile = false
          this.childFolders = res.children
          this.parentId = res.parent
        })
      } else {
        await this.FETCH_FOLDERS_LIST().then(res => {
          this.onFile = false
          this.childFolders = res.results
          this.parentId = null
          this.toBackBtn = true
        })
      }
      this.overlay1 = false
    },
    showFile(item) {
      const title = item.split('/')
      return title[title.length - 1]
    },
  },
}
</script>

<style scoped>
.vh-90 {
  height: 80vh;
}
.py-10 {
  padding: 0x 0;
}
.bl {
  top: 0;
  bottom: 0;
  right: 0;
}
.bl1 {
  top: 0;
  right: 15px;
}
.bl2 {
  padding: 10px;
  border-radius: 50%;
}
.min-h {
  min-height: 200px;
}

.hov{
  border-radius: 4px;
  transition: all.3s;
  box-shadow: 0 2px 5px 0 rgb(34 41 47 / 0%), 0 2px 10px 0 rgb(34 41 47 / 12%);
}
.hov:hover{
  background: #cccccc38;

}
.file-text {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: auto;
  padding-bottom: 5px;
}
.min-w {
  min-width: 300px;
}
</style>
