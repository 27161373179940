<template>
  <b-modal
    id="AddFileModal"
    :title="isFileAdd? $t('courses.add_file') : $t('courses.create_folder')"
    size="sm"
    ok-variant="success"
    cancel-variant="danger"
    :ok-title="$t('general.save')"
    :cancel-title="$t('general.cancel')"
    centered
    no-close-on-backdrop
    :ok-disabled="isSentFile"
    @show="onShow"
    @hide="onHide"
    @ok.prevent="submit"
    @cancel="$bvModal.hide('AddFileModal')"
  >
    <validation-observer ref="fileValidation">

      <b-form-group
        v-if="!isFileAdd"
        :label="$t('general.title')"
        label-for="title_input"
      >
        <validation-provider
          #default="{ errors }"
          rules="required"
          :name="$t('general.title')"
        >
          <b-form-input
            id="title_input"
            v-model="data.title"
            :placeholder="$t('general.title')"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <template
        v-if="isFileAdd"
      >
        <b-form-radio-group
          v-model="card"
          class="mb-2 list item-view-radio-group"
          buttons
          size="sm"
          button-variant="outline-primary"
        >
          <b-form-radio
            v-for="option in itemViewOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-radio>
        </b-form-radio-group>
        <template v-if="card">
          <b-form-group
            :label="$t('courses.file')"
            label-for="file"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('courses.file')"
            >
              <b-form-file
                id="file"
                v-model="selectedFile.file"
                :disabled="isSentFile"
                :browse-text="$t('general.upload')"
                :placeholder="$t('general.select_file')"
                :drop-placeholder="$t('general.select_file')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <template v-else>
          <b-form-group
            :label="$t('courses.link')"
            label-for="title_input"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('courses.link')"
            >
              <b-form-input
                id="title_input"
                v-model="selectedFile.url"
                :placeholder="$t('courses.paste_link')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>

        <b-progress
          v-if="isSentFile"
          :value="progress"
          max="100"
          striped
          show-value
        />
      </template>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormInput,
  BFormGroup,
  BFormFile,
  BProgress,
  BFormRadio,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { mapActions } from 'vuex'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import http from '@/libs/axios'
import formmatter from '@/mixins/formmatter'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BProgress,
    BFormRadio,
    BFormRadioGroup,
  },
  mixins: [formmatter],
  props: {
    parent: {
      type: null,
      required: false,
      default: null,
    },
    isFileAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    editData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      data: {
        parent: null,
        title: '',
      },
      card: true,
      required,
      selectedFile: {
        parent: null,
        file: null,
        is_folder: false,
        url: null,
      },
      link: '',
      progress: 0,
      isSentFile: false,
      itemViewOptions: [
        {
          text: this.$t('courses.file'),
          value: true,
        },
        {
          text: this.$t('courses.link'),
          value: false,
        },
      ],
    }
  },
  methods: {
    ...mapActions('content', [
      'FETCH_FOLDERS_LIST',
      'FETCH_ONE_FOLDER',
      'DELETE_FOLDERS',
      'CREATE_FOLDERS',
      'UPDATE_FOLDER',
    ]),
    onShow() {
      this.isSentFile = false
      if (this.isEdit) {
        this.data.title = JSON.parse(JSON.stringify(this.editData.title))
        this.data.parent = JSON.parse(JSON.stringify(this.editData.parent))
        this.data.id = JSON.parse(JSON.stringify(this.editData.id))
      } else {
        this.selectedFile = {
          parent: null,
          file: null,
          is_folder: false,
          url: null,
        }
        this.data = {
          parent: null,
          title: '',
        }
      }
    },
    onHide() {
      this.progress = 0
    },
    refreshLeftbar(el) {
      if (el) this.FETCH_ONE_FOLDER(el)
    },
    submit() {
      this.$refs.fileValidation.validate().then(async success => {
        if (success) {
          if (!this.isEdit) {
            if (!this.isFileAdd) {
              if (this.parent) {
                this.$set(this.data, 'parent', this.parent)
              }
              this.CREATE_FOLDERS(this.data)
                .then(() => {
                  this.$_successToast(this.$t('general.added'))
                  this.$bvModal.hide('AddFileModal')
                  this.FETCH_FOLDERS_LIST().then(() => {
                    this.refreshLeftbar(this.parent)
                  })
                })
                .catch(() => {
                  this.$_errorToast(this.$t('general.error'))
                })
            } else {
              if (this.parent) {
                this.$set(this.selectedFile, 'parent', this.parent)
              }
              if (this.card) {
                this.selectedFile.url = null
                this.isSentFile = true
                const formData = this.$_objectToFormData(this.selectedFile)
                await http.post('/file_management/content-file-folder/', formData, {
                  onUploadProgress: progressEvent => {
                    this.progress = (progressEvent.loaded * 100) / progressEvent.total
                  },
                  timeout: 0,
                })
                  .then(() => {
                    this.$_successToast(this.$t('general.added'))
                    this.$bvModal.hide('AddFileModal')
                    this.FETCH_FOLDERS_LIST().then(() => {
                      this.refreshLeftbar(this.parent)
                    })
                  })
                  .catch(() => {
                    this.$_errorToast(this.$t('general.error'))
                  })
              } else {
                this.selectedFile.file = null
                this.isSentFile = false
                this.CREATE_FOLDERS(this.selectedFile)
                  .then(() => {
                    this.$_successToast(this.$t('general.added'))
                    this.$bvModal.hide('AddFileModal')
                    this.FETCH_FOLDERS_LIST().then(() => {
                      this.refreshLeftbar(this.parent)
                    })
                  })
                  .catch(() => {
                    this.$_errorToast(this.$t('general.error'))
                  })
              }
            }
          } else {
            this.UPDATE_FOLDER(this.data)
              .then(() => {
                this.$_successToast(this.$t('general.edited'))
                this.$bvModal.hide('AddFileModal')
                this.FETCH_FOLDERS_LIST().then(() => {
                  this.refreshLeftbar(this.parent)
                })
              })
              .catch(() => {
                this.$_errorToast(this.$t('general.error'))
              })
          }
        }
      })
    },
  },
}
</script>

<style>

</style>
